// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {

      const blockchain = store.getState().blockchain;
      let tokensOfOwner = await store.getState().blockchain.smartContract.methods.tokensOfOwner(blockchain.account).call();
      let tokensOfOwnerOriginalNFT = await store.getState().blockchain.smartContract.methods.tokensOfOwnerOriginalNFT(blockchain.account).call();
      let totalSupply = await store.getState().blockchain.smartContract.methods.totalSupply().call();
      //let tokenIdOfLockerOriginal = await store.getState().blockchain.smartContract.methods.SBTTokenIdByTokenId(1).call();
      // let cost = await store
      //   .getState()
      //   .blockchain.smartContract.methods.cost()
      //   .call();

      dispatch(
        fetchDataSuccess({
          totalSupply,
          tokensOfOwner,
          tokensOfOwnerOriginalNFT,
          //tokenIdOfLockerOriginal,
          // cost,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
